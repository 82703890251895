/* voces-regular - latin */
@font-face {
  font-family: 'Voces';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/voces-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Voces Regular'), local('Voces-Regular'),
       url('../fonts/voces-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/voces-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/voces-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/voces-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/voces-v7-latin-regular.svg#Voces') format('svg'); /* Legacy iOS */
}

#loader { transition: opacity 0.5s ease-in-out; width: 100%; height: 100%; position: fixed;  display: flex;}
#loader img {margin: auto 1% auto auto;}
#loader span {font-size: 50px;  font-family: 'Voces', sans-serif;text-decoration: none;line-height: 2; margin: auto auto auto 1%; background: rgb(32,125,193);
background: -moz-linear-gradient(-45deg, rgba(32,125,193,1) 0%, rgba(102,159,211,1) 100%);
background: -webkit-linear-gradient(-45deg, rgba(32,125,193,1) 0%,rgba(102,159,211,1) 100%);
background: linear-gradient(135deg, rgba(32,125,193,1) 0%,rgba(102,159,211,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207dc1', endColorstr='#669fd3',GradientType=1 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;}
	
.js #loader {opacity: 0;display: none;}
.no-js #loader {opacity: 1;}

.js #wrapper { opacity: 1; transition: opacity 0.5s ease-in-out; }
.no-js #wrapper { opacity: 0;}