.fa.fa-heart {color: #F0288A; font-size: 50px;}
.demo-wrapper.contact { margin: 40px auto 0;}
label {font-weight: 400;}
.btn {
    white-space: normal;
}
.gdprCheckbox a {
    border-bottom: 1px dotted; 
}
.gdprCheckbox a:hover {
    text-decoration: none;
}
        .control {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
        }
            .control input {
                position: absolute;
                z-index: -1;
                opacity: 0;
            }
        .control_indicator {
            position: absolute;
            top: 2px;
            left: 0;
            height: 20px;
            width: 20px;
            border:1px solid #bbbbbb;
        }
        .control-radio .control_indicator {
            border-radius: undefined%;
        }
        
        .control:hover input ~ .control_indicator,
        .control input:focus ~ .control_indicator {
            border-color:#207dc1;

        }
        
        .control input:checked ~ .control_indicator {
            background:#207dc1;
        }
        .control:hover input:not([disabled]):checked ~ .control_indicator,
        .control input:checked:focus ~ .control_indicator {
            background: #0e6647d;
        }
        .control input:disabled ~ .control_indicator {
            background: #e6e6e6;
            opacity: 0.6;
            pointer-events: none;
        }
        .control_indicator:after {
            box-sizing: unset;
            content: '';
            position: absolute;
            display: none;
        }
        .control input:checked ~ .control_indicator:after {
            display: block;
        }
        .control-checkbox .control_indicator:after {
            left: 8px;
            top: 4px;
            width: 3px;
            height: 8px;
            border: solid #ffffff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        .control-checkbox input:disabled ~ .control_indicator:after {
            border-color: #7b7b7b;
        }