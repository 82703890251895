/*

	Theme Name: avana LLC

	Template URI:

	Description:

	Author:

	Author URI:

	License:

	License URI:

	Version: 1.0



	1. Body style

	2. Header

	3. Main

	4. Footer

	5. About



*/
/*Bootstrap 4 rules*/
.mt-3 {
	margin-top: 1.5rem !important;
}
.mb-5, .my-5 {
    margin-bottom: 3rem !important;
}
.mr-1, .mx-1 {
    margin-right: 0.25rem !important;
}
/*Bootstrap 4 rules*/
h2 {
	color: #404040;
}
.portfolio h2 {
	color: #000;
	font-size: 21px;
	line-height: 30px;
	font-weight: 800;
}
.portfolio section:hover h2 {
	color: #fff;
}
img {
	max-width: 100%;
	height: auto;
}
#gmap img {
	max-width: 100%!important;
}
/* global box-sizing */
*,
*:after,
*:before {
	-moz-box-sizing:border-box;
  -webkit-box-sizing: border-box;
	box-sizing:border-box;
	-webkit-font-smoothing:antialiased;
	font-smoothing:antialiased;
	text-rendering:optimizeLegibility;
	}
html {

	-ms-text-size-adjust: 100%;

	-webkit-text-size-adjust: 100%;

}
input, textarea {

	-webkit-appearance: none;

	-webkit-border-radius: 0;

}

button, html input[type="button"],/* 1 */ input[type="reset"], input[type="submit"] {

	-webkit-appearance: button;

	cursor: pointer;

 *overflow:visible;

}

body, img,.commentys-form input[type="text"],.commentys-form input[type="email"],.commentys-form input[type="url"],.commentys-form textarea {

	transition: all .2s linear;

	-o-transition: all .2s linear;

	-moz-transition: all .2s linear;

	-webkit-transition: all .2s linear;

}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, input, main {

	-webkit-box-sizing: border-box;

	-moz-box-sizing: border-box;

	box-sizing: border-box;

	margin: 0;

	padding: 0;

	vertical-align: baseline;

	border: 0;

	outline: 0;

}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, img, main {

	display: block

}

audio, canvas, video {

	display: inline-block;

 *display:inline;

 *zoom:1;

}

blockquote:before, blockquote:after, q:before, q:after {

	content: '';

	content: none;

}

.clear {

	clear: both;

	line-height: 0;

	height: 0;

}

a {

	text-decoration: none;

	outline: none;

	color: #010101;

	transition-delay: 0s;

	transition-duration: 0.6s;

	transition-property: all;

	transition-timing-function: ease;

}

a:focus, img:focus, button:focus, .btn:focus {outline: none;}

::-moz-selection {background-color:#207dc1;color:#fff;text-shadow:none;}

::selection {background-color: #207dc1;color: #fff;text-shadow: none;}

@font-face {font-family: 'Open Sans', sans-serif;}


/*===== Header ===*/
#root {
  padding-top: 80px;
}

header[role="header"] {
  background: #2d2d2d;
  display: flex;
  height: 80px;
  position: fixed;
  top: 0;
  transition: top 0.2s ease-in-out;
  width: 100%;
  z-index: 999;
}
.body--open header[role="header"].nav-down,
header[role="header"].nav-up {
  top: -80px;
}

header[role="header"] h1{ padding:0; margin:0}

header[role="header"] h1 > a{ max-width:66px; display:block; float:left}

header[role="header"] nav{ padding-top:10px;transition-delay: 0s;

transition-duration: 0.6s;transition-property: all;transition-timing-function: ease;
}

header[role="header"] nav ul{
	display: none;
	position: fixed;
z-index: 60;
text-align: center;
width: 100%;
height: 100%;
top: 0;
left: 0;
right: 0;
background-color: rgba(255,255,255,0.8);
padding: 0;
margin: 0;
flex-flow: column;
justify-content: center;}

header[role="header"] nav ul > li{ font-weight:800; font-size:42px; display:flex}

header[role="header"] nav ul > li > a{ display: flex;
line-height: 72px;
color: #404040;
justify-content: center;
width: 100%;
line-height: 10vh;}

header[role="header"] nav ul > li a.active,
header[role="header"] nav ul > li a:hover,
header[role="header"] nav ul > li:hover a,
header[role="header"] nav ul > li.nav-active a{ text-decoration:none; color:#207dc1}

header[role="header"] nav #menu-button {width: 100px;font-size: 0;float: right;height: 19px;position: relative; z-index:70; margin-right: 10px;

-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);-webkit-transition: .5s ease-in-out;-moz-transition: .5s ease-in-out;-o-transition: .5s ease-in-out;transition: .5s ease-in-out;cursor: pointer;}

header[role="header"] nav #menu-button span {display: block;position: absolute;z-index: 60;height: 3px;

width: 31px;background: #111111;opacity: 1;right: 0;

-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-o-transform: rotate(0deg);

transform: rotate(0deg);-webkit-transition: .25s ease-in-out;-moz-transition: .25s ease-in-out;-o-transition: .25s ease-in-out;transition: .25s ease-in-out;}

header[role="header"] nav #menu-button span {background: rgb(32,125,193);
background: -moz-linear-gradient(-45deg, rgba(32,125,193,1) 0%, rgba(102,159,211,1) 100%);
background: -webkit-linear-gradient(-45deg, rgba(32,125,193,1) 0%,rgba(102,159,211,1) 100%);
background: linear-gradient(135deg, rgba(32,125,193,1) 0%,rgba(102,159,211,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207dc1', endColorstr='#669fd3',GradientType=1 );}

header[role="header"] nav #menu-button span:nth-child(1) {top:0px;}

header[role="header"] nav #menu-button span:nth-child(2) {top:7px;}

header[role="header"] nav #menu-button span:nth-child(3) {top:14px;}

header[role="header"] nav #menu-button.open span:nth-child(1) {top: 10px;

-webkit-transform: rotate(135deg);-moz-transform: rotate(135deg);-o-transform: rotate(135deg);transform: rotate(135deg);}

header[role="header"] nav #menu-button.open span:nth-child(2) {opacity: 0;right: -60px;}

header[role="header"] nav #menu-button.open span:nth-child(3) {top: 10px;

-webkit-transform: rotate(-135deg);-moz-transform: rotate(-135deg);-o-transform: rotate(-135deg);transform: rotate(-135deg);}
header[role="header"] .container,header[role="header"] .custom-logo-link {display: flex;margin: auto; width: 100%;}
header .site-branding {
    flex: 2 1 0;
}
header[role="header"] .custom-logo-link {text-decoration: none;}
header[role="header"] nav span:first-child:after,
header[role="header"] .custom-logo-link span{font-size: 50px;       font-family: 'Voces','Open Sans',arial,helvetica,sans-serif;text-decoration: none;line-height: 0.8;  background: rgb(32,125,193);
background: -moz-linear-gradient(-45deg, rgba(32,125,193,1) 0%, rgba(102,159,211,1) 100%);
background: -webkit-linear-gradient(-45deg, rgba(32,125,193,1) 0%,rgba(102,159,211,1) 100%);
background: linear-gradient(135deg, rgba(32,125,193,1) 0%,rgba(102,159,211,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207dc1', endColorstr='#669fd3',GradientType=1 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;}

header[role="header"] nav span:first-child:after {
content: "Menu";
text-transform: uppercase;
font-size: 21px;
line-height: 20px;
padding: 0;
position: absolute;
left: -65px;}

header[role="header"] nav .open span:first-child:after {
	display: none;
}
header[role="header"] .container {max-width: 1280px;}
/*===== main ===*/
.btn.mt-2 {margin: 2rem 0;position: relative; z-index: 2;}
main[role="main-home-wrapper"],main[role="main-inner-wrapper"] { padding-bottom:90px; z-index: 0;}

main[role="main-home-wrapper"] article[role="pge-title-content"],main[role="main-inner-wrapper"] article[role="pge-title-content"]{ padding-left:41px}

main[role="main-home-wrapper"] article[role="pge-title-content"] header,main[role="main-inner-wrapper"] article[role="pge-title-content"] header{ padding-bottom:19px; padding-top:40px}

main[role="main-home-wrapper"] article[role="pge-title-content"] header h1,main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1{ font-size:42px; line-height:53px; color:#404040; font-weight:800}

main[role="main-home-wrapper"] article[role="pge-title-content"] header h1 span,main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1 span{ display:block; font-size:60px; color:#207dc1; padding-bottom:15px}

main[role="main-home-wrapper"] article[role="pge-title-content"] p,main[role="main-inner-wrapper"] article[role="pge-title-content"] p{ font-size:20px; line-height:30px; color:#454545}

/*===== footer ===*/

footer[role="footer"]{ background-color:#FFF; display: flex; flex-direction:column; padding-bottom:77px}
footer[role="footer"] .logoFooter {margin: auto}

footer[role="footer"] > h1 a{ margin:0 auto; display:block; max-width:66px;}

footer[role="footer"] nav{ padding-top:37px; padding-bottom:43px}

footer[role="footer"] nav > ul{ text-align:center; padding:0; margin:0}

footer[role="footer"] nav > ul > li{ display: inline-block; text-transform:uppercase; font-size:15px; margin:0 13px}

footer[role="footer"] nav > ul > li > a{ color:#9d9d9d}

footer[role="footer"] nav > ul > li:hover a, footer[role="footer"] nav > ul > li > a.active { text-decoration:underline; color:#207dc1}

footer[role="footer"] ul[role="social-icons"]{ padding:0; margin:0; text-align:center; padding-bottom:40px;}

footer[role="footer"] ul[role="social-icons"] > li{ display: inline-block; margin:0 6.5px}

footer[role="footer"] ul[role="social-icons"] > li > a{ display:block; width:42px; height:42px; background-color:#bbbbbb; color:#FFF; line-height:42px; font-size:20px; border-radius:100%; text-align:center}

footer[role="footer"] ul[role="social-icons"] > li:hover a{ background-color:#207dc1}

footer[role="footer"] .copy-right{ text-align:center; display:block; font-size:12px; line-height:13px; color:#9c9c9c}
#cookie-bar.fixed.bottom {
bottom: 2vh;
padding: 0 2rem;
top: auto;
height: 50px;
width: 60vw;
left: 1vw;
}
#cookie-bar.fixed.bottom p {
    margin-right: 2%;
    width: 70%;
}
#cookie-bar.cookie-message a.btn {
	color: #ffffff;
	height: 30px;
line-height: 11px;
 }
/*===== About ===*/

main[role="main-inner-wrapper"] .about-content{ background-color: #207dc1;
padding-top: 50px;
padding-left: 70px;
padding-right: 67px;
padding-bottom: 30px;
float: left;
margin-top: 30px;}

main[role="main-inner-wrapper"] .about-content p{ font-size:17px; line-height:30px; color:#FFF; padding-bottom:20px}

main[role="main-inner-wrapper"] .thumbnails-pan{ padding-top:30px}

main[role="main-inner-wrapper"] .thumbnails-pan section figure{ background-color:#FFF; position:relative; overflow:hidden; cursor:pointer}

main[role="main-inner-wrapper"] .thumbnails-pan section figure,main[role="main-inner-wrapper"] .thumbnails-pan section figure, img,main[role="main-inner-wrapper"] .thumbnails-pan section figure figcaption,section.blog-content figure,section.blog-content figure img,section.blog-content  article{transition-delay: 0s;transition-duration: 0.6s;transition-property: all;transition-timing-function: ease;}

main[role="main-inner-wrapper"] .thumbnails-pan section figure figcaption{ position:absolute; bottom:-50%; left:0; right:0; background-color:#207dc1; margin:0 69px; text-align:center; color:#FFF; padding-top:16px; padding-bottom:27px}

main[role="main-inner-wrapper"] .thumbnails-pan section figure figcaption h3{ font-size:21px; line-height:22px; font-weight:300}

main[role="main-inner-wrapper"] .thumbnails-pan section figure figcaption h5{ text-transform:uppercase; font-size:13px; line-height:14px; font-weight:bold}

main[role="main-inner-wrapper"] .thumbnails-pan section figure:hover,section.blog-content:hover article{ background-color:#207dc1; color:#FFF}

main[role="main-inner-wrapper"] .thumbnails-pan section figure:hover img,section.blog-content:hover figure img{-webkit-transform: scale3d(1.08,1.08,2);transform: scale3d(1.08,1.08,2); opacity:0.5}

main[role="main-inner-wrapper"] .thumbnails-pan section figure:hover figcaption{ bottom:0;}

/*===== Blog ===*/
:-moz-ui-invalid:not(output) {
    box-shadow: none;
}
article[role="pge-title-content"].blog-header{ padding-bottom:40px; text-align: left;}

section.blog-content{ margin-bottom:29px}

section.blog-content figure{ overflow:hidden; position:relative;background-color: rgba(255,255,255,0.4); cursor:pointer}
section.blog-content figure .post-date {background-color: rgba(255, 255, 255, 1);
height: 130px;
position: absolute;
width: 100%;
top: 0;
left: 0;
z-index: 1;opacity: 0.5}
section.blog-content figure .post-date span{ text-align:center; color:#207dc1; font-weight:800; font-size:14px; line-height:18px; display:block; background-color:transparent; width:130px; height:130px; position:absolute; left:0; top:0; padding-top:41px; z-index:50; padding-left: 15px;}

.product:hover section.blog-content figure .post-date {opacity: 1;
	-webkit-transition: opacity 0.35s;

	transition: opacity 0.35s;}
	.product:hover section.blog-content figure .post-date span {background-color:#2d2d2d;
	-webkit-transition: background-color 0.35s;

	transition: background-color 0.35s;}
section.blog-content figure .post-date span{ font-size:40px; line-height:35px; display:block}

section.blog-content  article{ font-size:21px; line-height:30px; color:#FFF;  font-weight:800; color:#343434; padding-top:33px; padding-bottom:31px;}

section.blog-content:hover figure img{ opacity:0.5}

/*===== Contact ===*/

article[role="pge-title-content"].contact-header{ padding-bottom:0}


    article[role="pge-title-content"] p.liens.liens {
        display: flex;
        flex-flow: row wrap;
    }
article[role="pge-title-content"] p.liens a{ color:#343434; margin: auto;}

article[role="pge-title-content"] p.liens a:hover{color:#207dc1; text-decoration:none}

.demo-wrapper {width: 100%;margin: 0 auto;}

#surabaya {width: 100%;height: 100%;}

.error_message{ color:#e84d49}

#success_page h3,#success_page p{color:#60ca6f }

.wrapper{
margin: 0 auto 3rem auto;
width: 100%;
}
.contat-form.wrapper{
	display: flex;
flex-flow: column;
}

.contat-form.wrapper input[type="text"],.contat-form.wrapper input[type="email"],.contat-form.wrapper textarea{ width:100%; display:block; outline:none; border-bottom:1px solid #bbbbbb; background-color:inherit; color:#404040; font-size:21px; line-height:23px; padding-bottom:24px}


.contat-form.wrapper input[type="text"]:focus,.contat-form.wrapper input[type="email"]:focus,.contat-form.wrapper textarea:focus{ border-bottom-color:#9f9e9e}

.contat-form.wrapper form ::-webkit-input-placeholder { color:#404040; }

.contat-form.wrapper form ::-moz-placeholder { color:#404040; } /* firefox 19+ */

.contat-form.wrapper form :-ms-input-placeholder { color:#404040; } /* ie */

.contat-form.wrapper form input:-moz-placeholder { color:#404040; }

.contat-form.wrapper textarea{ border-bottom:1px solid #bbbbbb; border-left:none; border-right:none; border-top:none; height:77px; margin-top:56px}

.contat-form.wrapper input[type="submit"]{ display:block; border:none; outline:none; padding: 0 30px; line-height:60px; text-transform:uppercase; font-size:21px; color:#FFF; font-weight:800; letter-spacing:2px; background-color:#207dc1; margin:41px auto; margin-bottom:0}

.contat-form.wrapper input[type="submit"]:hover{background-color:#669fd3;
	-webkit-transition: background-color 0.35s;

	transition: background-color 0.35s;
}

/*== Work Details ==*/

.work-details,.blog-details{ padding:0 69px; margin-top:73px}

.work-details header h2{ color:#343434; font-size:36px; font-weight:800}

.work-details header a{ display:inline-block; color:#207dc1; font-size:18px;font-weight:800;padding-top:3px}

.work-details header a i{ color:#0d0d0d; padding-left:10px}

.work-details header a:hover{ color:#0d0d0d; text-decoration:none}

.work-details header a:hover i{ color:#207dc1}

.work-details p{ font-size:16px; color:#666666; line-height:30px; margin-bottom:20px; font-weight:300}

.work-details p strong{ font-weight:800}

.work-images{ margin-top:46px}

.work-images li{ margin-bottom:50px}

/*== Blog Details ==*/


.blog-header h1,.comments-pan h1,.commentys-form h4{ color:#404040;font-weight:800; padding-bottom:24px; display:block}

.blog-header h1 span{ color:#207dc1}

.blog-details h2{ font-size:36px; font-weight:normal; padding-bottom: 20px;}

.blog-details .enter-content{ margin-top:62px}

.blog-details .enter-content p{ font-size:16px; line-height:30px; font-weight:300; margin-bottom:23px}

.comments-pan{border-top:2px solid #dedede; padding-top:15px}

.comments-pan h3{border-bottom:2px solid #dedede; padding-bottom:34px; margin-bottom:52px}

.comments-reply,.reply-pan{ padding:0; margin:0; list-style:none}

.comments-reply li{ display:block; border-bottom:2px solid #dedede; overflow:hidden; padding-bottom:51px; margin-bottom:39px}

.comments-reply li figure{ float:left; width:70px; background-color:#000}

.comments-reply li section{ float:left; padding-left:30px; font-size:16px; line-height:30px; font-weight:300; color:#343434;width: 92%;}

.comments-reply li section .date-pan{ font-size:14px; line-height:18px; padding-bottom:25px}

.comments-reply li section h4{font-weight:800; color:#404040; font-size:21px; margin-bottom:6px; margin-top:0}

.comments-reply li section h4 a{ font-size:16px; color:#207dc1;font-weight:400; display:inline-block; padding-left:20px}

.reply-pan{ clear:both; display:block; margin-left:100px;padding-top:39px }

.reply-pan li{ border-bottom:none;border-top:2px solid #dedede; padding-bottom:0; margin-bottom:0;padding-top:38px;}

.commentys-form h4{ font-size:24px}

.commentys-form form{ margin-top:30px}

.commentys-form input[type="text"],.commentys-form input[type="email"],.commentys-form input[type="url"],.commentys-form textarea{ font-size:21px; line-height:22px; color:#404040; padding-bottom:26px; border-bottom:2px solid #bbbbbb; width:100%; display:block; border-left:none; border-right:none; border-top:none; background-color:transparent; outline:none}

.commentys-form textarea{ margin-top:57px}

.commentys-form input[type="text"]:focus,.commentys-form input[type="email"]:focus,.commentys-form input[type="url"]:focus,.commentys-form textarea:focus{ border-bottom-color:#000}

.commentys-form input[type="button"]{ padding:0 36px; text-transform:uppercase; display:inline-block; font-size:21px; line-height:60px; color:#FFF; font-weight: bold; text-align:center; border:none; outline:none; background-color:#207dc1; margin-top:50px}

.commentys-form input[type="button"]:hover{ background-color:#ff5c5c}

@media (min-width:767px){
	.captureBlog {
		padding-top: 40px;
	}
	.contact-header.thanx {
		display: flex;
		height: 180px;
	}
	.contact-header.thanx p {
		display: flex;
		margin: auto auto 0;
	}
}
@media (min-width:991px){
	.captureBlog {
		padding-top: 205px;
	}
}
@media (max-width:768px) {

	.contat-form.wrapper input[type="text"].inputName {
		margin-top: 3rem;
	}
}

main[role="main-inner-wrapper"] article[role="pge-title-content"] p.rdvclients, p.rdvclients  {
	text-align: center;
	font-weight: 800;
}
main[role="main-inner-wrapper"] article[role="pge-title-content"] p.rdvclients a, p.rdvclients a {
	text-align: center;
	color: #207dc1;
}
.stackoverflow-profile {
position: relative;
background-color: var(--color-bg);
display: -webkit-flex;
padding: 5px;
display: flex;
max-width: 235px;
-webkit-align-items: center;
margin-top: 20px;
align-items: center;

margin-bottom: 20px;

font-size: 13px;

color: #484848;
}
.stackoverflow-profile .bottom  {
	-webkit-flex: 1;

flex: 1;

position: relative;

display: -webkit-flex;

display: flex;

-webkit-flex-wrap: wrap;

flex-wrap: wrap;

padding-left: 10px;

margin: -3px -15px;

list-style-type: none;

color: #737373;
}
.stackoverflow-profile .bottom .profile-stats-repo {
	width: 100%;
	padding-left:10px;
}
.stackoverflow-profile .bottom .profile-stats-repo strong {
	font-size:22px;
}
.stackoverflow-profile .bottom .profile-stats-badge-gold {
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
-webkit-justify-content: center;
justify-content: center;
border-radius: 4px;
margin: 3px;
padding: 3px 10px;
border: 1px solid #ece3c8;
background-color: #faf4e3;
}
.stackoverflow-profile .bottom .profile-stats-badge-gold::before {
content: "";
display: block;
width: 10px;
height: 10px;
margin-right: 5px;
border-radius: 4px;
background-color: #ffcc01;
}
.stackoverflow-profile .bottom .profile-stats-badge-silver {
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
-webkit-justify-content: center;
justify-content: center;
border-radius: 4px;
margin: 3px;
padding: 3px 10px;
border: 1px solid #eff0f1;
background-color: #fdfdfd;
}
.stackoverflow-profile .bottom .profile-stats-badge-silver::before {
content: "";
display: block;
width: 10px;
height: 10px;
margin-right: 5px;
border-radius: 4px;
background-color: #b4b8bc;
}
.stackoverflow-profile .bottom .profile-stats-badge-bronze {
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
-webkit-justify-content: center;
justify-content: center;
border-radius: 4px;
margin: 3px;
padding: 3px 10px;
border: 1px solid #f0e1d6;
background-color: #f6efe8;
}
.stackoverflow-profile .bottom .profile-stats-badge-bronze::before {
content: "";
display: block;
width: 10px;
height: 10px;
margin-right: 5px;
border-radius: 4px;
background-color: #d1a684;
}
