.grid {

	position: relative;

	list-style: none; overflow:hidden; 

	text-align: center;

}



/* Common style */

.grid figure {

	position: relative;margin-bottom:31px;

	overflow: hidden;

	min-width: 100%;

	max-width: 100%;

	width:100%;

	height: 100%; min-height:100%;

	background: #3085a3;

	text-align: center;

	cursor: pointer;

}


.grid.first {

	margin-bottom: 60px;
    margin-top: 60px;

}
.clearfix.homerow {height: 31px;
float: left;
width: 100%;}
.grid figure img {

	position: relative;

	display: block;

	min-height: 100%;

	max-width: 100%;

	opacity: 0.8;
height: auto;
}



.grid figure figcaption {

	padding:0;

	color: #fff;

	text-transform: uppercase;

	font-size: 1.25em;

	-webkit-backface-visibility: hidden;

	backface-visibility: hidden;

	line-height:100%;

}

.grid figure figcaption header a{}

.grid figure figcaption::before,

.grid figure figcaption::after {

	pointer-events: none;

}



.grid figure figcaption,

.grid figure figcaption > a {

	position: absolute;

	top: 0;

	left: 0;

	width: 100%;

	height: 100%;

}



/* Anchor will cover the whole item by default */

/* For some effects it will show as a button */

.grid figure figcaption > a {

	z-index: 1000;

	text-indent: 200%;

	white-space: nowrap;

	font-size: 0;

	opacity: 0;

}



.grid figure h2 {

	word-spacing: -0.15em;

	font-weight:400; color:#FFF;

}



.grid figure h2 span {

	font-weight: 800;

}



.grid figure h2,

.grid figure p {

	margin: 0;

}



.grid figure p {

	letter-spacing: 1px;

	font-size:16px; line-height:22px;

}





/* Individual effects */




/*---------------*/

/***** Oscar *****/

/*---------------*/

figure.effect-oscar {
background: rgb(32,125,193);
background: -moz-linear-gradient(-45deg, rgba(32,125,193,1) 0%, rgba(102,159,211,1) 100%);
background: -webkit-linear-gradient(-45deg, rgba(32,125,193,1) 0%,rgba(102,159,211,1) 100%);
background: linear-gradient(135deg, rgba(32,125,193,1) 0%,rgba(102,159,211,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207dc1', endColorstr='#669fd3',GradientType=1 );

}



figure.effect-oscar img {

	opacity: 1;

	-webkit-transition: opacity 0.35s;

	transition: opacity 0.35s;

}



figure.effect-oscar figcaption {

	padding: 4em;

	background-color: rgba(58,52,42,0);

	-webkit-transition: background-color 0.35s;

	transition: background-color 0.35s;

}



figure.effect-oscar figcaption::before {

	position: absolute;

	top: 30px;

	right: 30px;

	bottom: 30px;

	left: 30px;

	border: 1px solid #fff;

	content: '';

}



figure.effect-oscar h2 {

font-weight:400;	margin: 20% 0 10px 0;
text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
	-webkit-transition: -webkit-transform 0.35s;

	transition: transform 0.35s;

	-webkit-transform: translate3d(0,100%,0);

	transform: translate3d(0,100%,0);

}

figure.effect-oscar h2 span{

	font-weight:800

}

figure.effect-oscar figcaption::before,

figure.effect-oscar:hover p {

	font-weight:400;

	opacity: 0;

	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;

	transition: opacity 0.35s, transform 0.35s;

	-webkit-transform: scale(0);

	transform: scale(0);

}



figure.effect-oscar h2,
figure.effect-oscar:focus h2,
figure.effect-oscar:hover h2 {

	-webkit-transform: translate3d(0,0,0);

	transform: translate3d(0,0,0);

}



figure.effect-oscar figcaption::before,
figure.effect-oscar:hover figcaption::before,
figure.effect-oscar:focus figcaption::before,
figure.effect-oscar:focus p,

figure.effect-oscar:hover p {

	opacity: 1;

	-webkit-transform: scale(1);

	transform: scale(1);

}



figure.effect-oscar figcaption,
figure.effect-oscar:focus figcaption,
figure.effect-oscar:hover figcaption {

	background-color: rgba(58,52,42,0);

}



figure.effect-oscar img,
figure.effect-oscar:focus img,
figure.effect-oscar:hover img {

	opacity: 0.4;

}



@media screen and (max-width: 50em) {

	.content {

		padding: 0 10px;

		text-align: center;

	}

	.grid figure {

		display: inline-block;

		float: none;

		margin: 10px auto;

		width: 100%;

	}

}

@media (min-width: 768px) and (max-width: 991px) {

	figure.effect-oscar figcaption::before{ left:10px; right:10px; top:10px; bottom:10px;}

	figure.effect-oscar figcaption{ padding:0em}

	figure.effect-oscar h2{ font-size:20px;}

	figure.effect-oscar p{ font-size:13px;  padding:2%; display:block; width:90%; margin:0 auto;}

}



@media (max-width: 767px) {

	figure.effect-oscar figcaption::before{ left:10px; right:10px; top:10px; bottom:10px;}

	figure.effect-oscar figcaption{ padding:0em}

	figure.effect-oscar h2{ font-size:15px;}

	figure.effect-oscar p{ font-size:13px;  padding:2%; display:block; width:90%; margin:0 auto;}

}


/*Portfolio*/
body {
	--color-text: #000;
	--color-bg: #fff;
	--color-link: #2d2d2d;
	--color-link-hover: #000;
	--color-info: #333;
	--color-menu: #031de6;
	--color-menu-hover: #000;
	--details-bg-up: #fff;
	--details-bg-down: #f5f0ef;
	--color-product-title: #000;
	--color-product-subtitle: #a09897;
	--color-details-title: #000;
	--color-details-subtitle: #000;
	--color-descr: #000;
	--color-price: var(--color-link);
	--color-bg-addtocart: #000;
	--color-addtocart: #fff;
	--color-close: #000;
	--color-bg-magnifier: #000;
	--color-magnifier: #fff;
	--color-btn-hover: #c1b3b1;
	color: #57585c;
	color: var(--color-text);
	background-color: #fff;
	background-color: var(--color-bg);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


/* Icons */
.icon {
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin: 0 auto;
	fill: currentColor;
}

main {
	position: relative;
	width: 100%;
}

.content {
	position: relative;
	display: block;
}

.grid {
	position: relative;
	display: grid;
	grid-template-columns: repeat(var(--grid-columns), 1fr);
}

.grid__item {
/*	padding: 0 4vw;
	margin: 0 0 12vh;*/
}

.grid__item:nth-child(odd) .product {
	margin-top: -8em;
}
.product {
	height: 100%;
	padding-top: 10em;
	position: relative;
	cursor: pointer;
}
#grid li:nth-child(2) .product {
    padding-top: 0;
}
#grid li.product:nth-child(2) {
    padding-top: 40px;
}
.product__bg {
	height: 20em;
	background: var(--details-bg-down);
	position: absolute;
}

.product__subtitle {
	position: relative;
	margin: 0;
	text-transform: uppercase;
	color: var(--color-product-subtitle);
	font-size: 0.85em;
	letter-spacing: 0.115em;
}

.product:hover .product__subtitle {
	color: var(--color-link);
}
.product__cart,
.product__description,
.product__price {
	opacity: 0;
	position: absolute;
}

.details {
	position: fixed;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	padding: 25vh 0 10vh 10vw;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	pointer-events: none;
}

.details--open {
	pointer-events: auto;
}

.details > * {
	position: relative;
	opacity: 0;
}

.details__bg {
	width: 100%;
	position: fixed;
	left: 0;
}

.details__bg--up {
	top: 0;
	height: 100vh;
	background: var(--details-bg-up);
}

.details__bg--down {
	top: 40vh;
	height: 60vh;
	background: var(--details-bg-down);
}


.details__img {
    position: absolute;
    top: 45vh;
    right: 10vw;
    height: auto;
    max-width: 30% !important;
    max-height: 40vh;
}

.details__bg,
.details__img {
	transform-origin: 0 0;
}

.details__title {
	margin: -1.5em 0 0.1em;
	font-size: 3.5em;
	color: var(--color-details-title);
	font-weight: 700;
}

.details__subtitle {
	text-transform: uppercase;
	margin: 0.75em 0 1em 0;
	letter-spacing: 0.115em;
	font-size: 1.75em;
	color: var(--color-details-subtitle);
}

.details__description {
	line-height: 1.5;
	font-weight: bold;
	max-width: 50%;
	margin: 2em 0 0 0;
	color: var(--color-details-desc);
}

.details__price {
	font-size: 3em;
	font-weight: bold;
	color: var(--color-price);
}

.details__addtocart {
	border: 0;
	margin: auto 0 0 0;
	background: var(--color-bg-addtocart);
	padding: 0.75em 2em;
	font-weight: bold;
}

.details__addtocart:hover {
	background: var(--color-btn-hover);
}
.details__addtocart a{
	color: var(--color-addtocart);
    text-decoration: none;
}
.details__close {
	position: absolute;
	top: 0;
	right: 0;
	border: 0;
	background: none;
	margin: 2em;
	cursor: pointer;
	font-size: 2em;
	color: var(--color-close);
}

.dummy-menu {
	border: 0;
	background: none;
	padding: 0;
	margin: 2.75em 2.5em 0 0;
	position: fixed;
	top: 0;
	right: 0;
	font-size: 0.85em;
	color: var(--color-close);
	cursor: pointer;
	z-index: 1000;
}

.details__magnifier {
	border: 0;
	background: none;
	background: var(--color-bg-magnifier);
	color: var(--color-magnifier);
	padding: 1em;
	border-radius: 50%;
	position: absolute;
	z-index: 1000;
	right: calc(-2.5vh + 10vw);
	top: 80vh;
	cursor: pointer;
}

.details__magnifier:hover {
	background: var(--color-btn-hover);
}

.details__deco {
	width: 7em;
	height: 20px;
	background-position: 50% 97%;
	background-size: 150%;
}