.no-js #loader img{
  animation: App-logo-spin infinite 5s linear;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 50px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
}
