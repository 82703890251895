
	header[role="header"] nav ul{ padding-top:1%}


/* md */
@media (min-width: 992px) and (max-width: 1199px) {
	.comments-reply li section{ width:88%}
	main[role="main-home-wrapper"] article[role="pge-title-content"] header h1, main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1 {
			font-size: 36px;
	}main[role="main-home-wrapper"] article[role="pge-title-content"] header h1 span, main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1 span {font-size: 50px;}
}
/* sm */
@media (min-width: 768px) and (max-width: 991px) {
	main[role="main-home-wrapper"] article[role="pge-title-content"],main[role="main-inner-wrapper"] article[role="pge-title-content"]{ padding-left:0}
	main[role="main-home-wrapper"] article[role="pge-title-content"] header,main[role="main-inner-wrapper"] article[role="pge-title-content"] header{ padding-top:0}
	main[role="main-home-wrapper"] article[role="pge-title-content"] header h1 span,main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1 span{ font-size:40px}
	main[role="main-home-wrapper"] article[role="pge-title-content"] header h1,main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1{ font-size:29px}
	main[role="main-home-wrapper"] article p br{ display:none}
	footer[role="footer"]{ padding:30px 0}
	footer[role="footer"] nav{ padding:15px 0}
	main[role="main-inner-wrapper"] .about-content{ padding:5%}
	main[role="main-inner-wrapper"] .thumbnails-pan section figure figcaption{ margin:0 10%}
	section.blog-content figure .post-date{ padding-top:20px; height:100px; width:100px}
	article.contact-header[role="pge-title-content"] p a{padding-right:10px}
	.contat-from-wrapper{ margin-top:10px; padding:0 15px}
	.grid-lod li{ width:100% !important; position:static !important; display:inline-block}
	.grid-lod li img{ width:100%}
	.work-details,.blog-details{ padding:0; margin-top:0}
	.comments-reply li section{ width:88%}
	figure.effect-oscar p {
    font-size: 12px;
	}
	#cookie-bar.fixed.bottom {
		width: 80vw;
	}
}
/* xs */
@media (max-width: 767px) {
	header[role="header"] nav ul{ padding-top:10%}
	header[role="header"] nav ul > li{ font-size:30px}
	header[role="header"] nav ul > li > a{ line-height:55px}
	main[role="main-home-wrapper"] article[role="pge-title-content"],main[role="main-inner-wrapper"] article[role="pge-title-content"]{ padding-left:0}
	main[role="main-home-wrapper"] article[role="pge-title-content"] header,main[role="main-inner-wrapper"] article[role="pge-title-content"] header{ padding-top:10px}
	main[role="main-home-wrapper"] article[role="pge-title-content"] header h1,main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1{ font-size:47px}
	main[role="main-home-wrapper"] article[role="pge-title-content"] header h1 span, main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1 span{ font-size:60px;}
	main[role="main-home-wrapper"] article{ padding-bottom:30px}
	main[role="main-home-wrapper"] article p br{ display:none}
	footer[role="footer"]{ padding:40px 0}
	footer[role="footer"] nav{ padding:15px 0}
	main[role="main-inner-wrapper"] .about-content{ padding:5%}
	main[role="main-inner-wrapper"] .thumbnails-pan section figure{ margin-bottom:20px}
	article[role="pge-title-content"].blog-header,article[role="pge-title-content"].contact-header{ padding-bottom:30px}
	section.blog-content figure .post-date{ padding-top:20px; height:100px; width:100px}
	section.blog-content article{ padding:2% 5%; font-size:18px; line-height:24px;}
	article.contact-header[role="pge-title-content"] p a{ font-size:18px; padding-right:10px}
	.contat-from-wrapper{ margin-top:10px; padding:0 15px}
	.contat-from-wrapper input[type="text"], .contat-from-wrapper input[type="email"]{ margin-bottom:20px}
	.contat-from-wrapper textarea{ margin-top:0}
	.work-details,.blog-details{ padding:0; margin-top:0}
	.work-images{ margin-top:0}
	.work-images li{ margin-bottom:0; width:100%}
	.work-images li figcaption h2{ font-size:18px;}
	.grid-lod li{ width:100% !important; position:static !important}
	.bog-header h3, .comments-pan h3, .commentys-form h4{ padding-bottom:0}
	.bog-header h2{ font-size:18px; line-height:26px}
	.bog-header{ padding-bottom:20px}
	.blog-details .enter-content{ margin-top:10px}
	.blog-details .enter-content p,.comments-reply li section{ font-size:14px; line-height:26px}
	.comments-pan h3{ padding-bottom:20px; margin-top:0}
	.comments-reply li section{ float:none; width:auto; padding-left:0}
	.comments-reply li figure{ margin-right:20px}
	.reply-pan{ margin-left:20px}
	.commentys-form input[type="text"], .commentys-form input[type="email"], .commentys-form input[type="url"], .commentys-form textarea{ margin-bottom:20px}
	.commentys-form textarea{ margin-top:0; margin-bottom:0}#cookie-bar.fixed.bottom {
    bottom: 2vh;
    padding: 0 2rem;
    top: auto;
    height: auto;
    width: 50vw;
    left: 1vw;
}
	
#formContact input#email {
        margin-top: 2.5rem;
}

}

/* xXs */

@media (max-width: 599px) {
	main[role="main-home-wrapper"] article[role="pge-title-content"] header h1,main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1{ font-size:24px;}

	main[role="main-home-wrapper"] article[role="pge-title-content"] header h1 span, main[role="main-inner-wrapper"] article[role="pge-title-content"] header h1 span{ font-size: 35px;
padding: 0;}    main[role="main-inner-wrapper"] .about-content p {
        font-size: 12px;
        line-height: 16px;
        padding-bottom: 0;
    }
    /*portfolio*/
    .details {
        padding-top: 17vh;
    }
    .details__img, .details__magnifier {
display: none;
    }
    .details__title {
        font-size: 2.5em;
    }
    .details__description {
    max-width: 90%;
}
    .details__subtitle {
    }
}

/* xXs */

@media (max-width: 480px) {
	#cookie-bar.fixed.bottom {
		bottom: 0;
		padding: 0 2rem;
		top: auto;
		height: auto;
		width: 100%;
	}
		#cookie-bar.fixed.bottom p {
    margin-right: 2%;
    width: 70%;
    display: flex;
    flex-flow: column;
}#cookie-bar.cookie-message a.btn {
    position: relative;
}
}